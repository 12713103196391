<template>
    <div class="topAnalysis">
        <!-- 导航 -->
        <page-head title="TOP榜单 - 分析">
            <template #right>
                <div class="filter_box">
                    <select-style
                        :list="filterType"
                        v-model:value="currentFilter"
                        @changeFn="changeFn($event, 'Filter')"
                        skin="white"
                        class="filterType"
                    ></select-style>
                    <select-style
                        :list="sexList"
                        v-model:value="currentSex"
                        @changeFn="changeFn($event, 'Sex')"
                        skin="white"
                        class="sex"
                    ></select-style>
                </div>
            </template>
        </page-head>
        <content-bg>
            <template #content>
                <div class="wrapper">
                    <div class="topAnalysis-content">
                        <div v-for="item in pageInfo.showList" :key="item" class="analysis-list">
                            <div class="analysis-item top">
                                <div class="label">
                                    <span>{{ item }}</span>
                                </div>
                                <template v-if="topAnalysisData[item].length">
                                    <div class="avatar_box">
                                        <img
                                            :src="$utils.defaultAvatar(topAnalysisData[item][0].playerAvatar)"
                                            alt=""
                                        />
                                    </div>
                                    <div class="name fs24">{{ topAnalysisData[item][0].playerName }}</div>
                                    <div class="score fs34">{{ topAnalysisData[item][0].showData }}</div>
                                    <!-- <div class="ratio fs32">80%</div> -->
                                </template>
                            </div>
                            <div class="scroll">
                                <div
                                    v-for="(player, index) in topAnalysisData[item].slice(1)"
                                    :key="player.id"
                                    class="analysis-item"
                                >
                                    <div class="index">{{ index + 2 }}</div>
                                    <div class="avatar_box">
                                        <img :src="$utils.defaultAvatar(player.playerAvatar)" alt="" />
                                    </div>
                                    <div class="name fs22">{{ player.playerName }}</div>
                                    <div class="score">{{ player.showData }}</div>
                                    <!-- <div class="ratio fs24">20%</div> -->
                                </div>
                                <no-data v-if="!topAnalysisData[item].length"></no-data>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </content-bg>
        <!-- 翻页 -->
        <pagination-com
            v-if="pageInfo.showList && pageInfo.showList.length > 0"
            class="pagination"
            :current-page="pageInfo.page"
            :total-page="pageInfo.totalPage"
            @changePage="changePage"
        />
    </div>
</template>

<script>
import { defineComponent, toRefs, reactive, getCurrentInstance, onMounted } from 'vue';
import { useStore } from 'vuex';
export default defineComponent({
    name: 'topAnalysis',
    setup() {
        const { proxy } = getCurrentInstance();
        const store = useStore();
        const state = reactive({
            filterType: [
                { name: '总数', id: 'total' },
                { name: '场均', id: 'averaging' },
                { name: '成功率', id: 'successRate' },
            ],
            currentFilter: { name: '总数', id: 'total' },
            sexList: [
                { name: '全部', id: 'all' },
                { name: '男', id: '1' },
                { name: '女', id: '2' },
            ],
            currentSex: { name: '全部', id: 'all' },
            videoLabelList: [], // 视频标签
            topAnalysisData: null,
            labelKeyList: [],
            pageInfo: {},
        });

        const changeFn = (event, type) => {
            state['current' + type] = event;
            getTopAnalysis();
        };

        // 获取青训营视频标签
        const getVideoClipLabel = () => {
            proxy.$server.getVideoClipLabel().then((res) => {
                if (res.code === 200) {
                    state.videoLabelList = res.data;
                    getTopAnalysis();
                }
            });
        };

        // 获取TOP-分析
        const getTopAnalysis = () => {
            const { id } = store.state.teamData;
            let params = {
                teensCampId: id,
                sex: state.currentSex.id === 'all' ? '' : state.currentSex.id,
                labels: state.videoLabelList.map((item) => item.key).join(),
                // labels: '向前斜传',
                type: state.currentFilter.id,
            };
            proxy.$server.teensCampClipListAnalysis(params).then((res) => {
                if (res && res.code === 200 && res.data) {
                    state.topAnalysisData = res.data;
                    state.labelKeyList = Object.keys(res.data);
                    state.pageInfo = proxy.$utils.paginationFn(state.labelKeyList, 1, 3);
                }
            });
        };

        // 页码发生变化时
        const changePage = (page) => {
            state.pageInfo = proxy.$utils.paginationFn(state.labelKeyList, page, 3);
        };

        onMounted(() => {
            getVideoClipLabel();
        });

        return {
            ...toRefs(state),
            changeFn,
            changePage,
        };
    },
});
</script>

<style lang="scss" scoped>
::v-deep .navigation-mian {
    width: 1410px;
    position: relative;
    z-index: 10;
}
::v-deep.pagination {
    position: absolute;
    left: 592px;
    top: 978px;
}
.filter_box {
    margin-left: auto;
    margin-right: 0;
    display: flex;
    .select {
        height: 78px;
        border-radius: 12px;
        ::v-deep .show {
            height: 78px;
            line-height: 78px;
        }
    }
    .filterType {
        width: 300px;
    }
    .sex {
        width: 240px;
        margin-left: 24px;
    }
}
.wrapper {
    box-sizing: border-box;
    height: 100%;
    padding: 20px 20px 0;
}
.topAnalysis-content {
    display: flex;
    height: 100%;
    .analysis-list {
        display: flex;
        flex-direction: column;
        max-width: 34%;
        flex: 1;
        margin-right: 24px;
        color: #ffffff;
        &:nth-child(1) .top {
            background: linear-gradient(91.37deg, #ff4437 41.91%, #ff8a82 129.77%);
            .label {
                background-color: #d2271c;
            }
        }
        &:nth-child(2) .top {
            background: linear-gradient(93.06deg, #6950ff 36.63%, #9483ff 119.19%);
            .label {
                background-color: #4b32e2;
            }
        }
        &:nth-child(3) .top {
            background: linear-gradient(91.37deg, #4575f9 41.91%, #83a3ff 129.77%);
            .label {
                background-color: #4b32e2;
            }
        }
        &:last-child {
            margin-right: 0;
        }
        .scroll {
            flex: 1;
            overflow-y: auto;
            overflow-x: hidden;
        }
        .analysis-item {
            display: flex;
            align-items: center;
            height: 65px;
            border-bottom: 1px solid rgba(255, 255, 255, 0.2);
            position: relative;
            &.top {
                box-sizing: border-box;
                height: 148px;
                padding: 34px 0 0 24px;
                border-radius: 12px;
                overflow: hidden;
                .avatar_box {
                    width: 80px;
                    height: 80px;
                }
                .name {
                    padding-left: 16px;
                }
            }
            & > div {
                box-sizing: border-box;
                padding: 0 12px;
            }
            .label {
                position: absolute;
                top: 0;
                left: -10px;
                transform: skewX(-20deg);
                height: 34px;
                padding: 0 20px;
                line-height: 34px;
                font-size: 24px;
                color: #ffffff;
                span {
                    display: inline-block;
                    transform: skewX(20deg);
                }
            }
            .index {
                width: 50px;
                font-size: 24px;
                font-family: akrobatRegular;
            }
            .avatar_box {
                width: 50px;
                height: 50px;
                padding: 0;
                border-radius: 10px;
                overflow: hidden;
            }
            .name {
                flex: 1;
            }
            .score,
            .ratio {
                width: 80px;
                font-family: akrobatRegular;
            }
        }
    }
    ::v-deep .no-data {
        font-size: 24px;
    }
}
img {
    max-width: 100%;
}
.fs22 {
    font-size: 22px;
}
.fs24 {
    font-size: 24px;
}
.fs34 {
    font-size: 34px;
}
</style>
